:root {
  --dark-gray: rgba(0, 0, 0, 0.788);
  --light-blue: #11b1da;
}

.contact {
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.contact h3 {
  font-size: 1.1rem;
}

.contact > .headline {
  align-self: flex-start;
}

.contact-form {
  width: 60%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  padding: 1.5rem;
  border-radius: 1.5rem;
  margin: auto;
  margin-top: 20px;
}


.contact-form input {
  width: 600px;
}

.contact-form button {
  margin: auto;
}

@media screen and (max-width: 1000px) {
  .contact .headline {
    font-size: 1.5rem;
  }

  .contact h3 {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 480px) {
  .contact .headline {
    font-size: 1.2rem;
  }
}