:root {
  --dark-gray: rgb(4, 5, 5);
  --light-blue: #11b1da;
}

.services {
  height: 100%;
  min-height: 100vh;
}

.description {
  font-size: 1.2rem;
}

.method-chart {
  width: 60%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
}

.method-detail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 5rem;
}

img.method-image {
  max-width: 300px;
  aspect-ratio: 1/1;
  border-radius: 50%;
}

.method-title {
  font-size: 2rem;
  line-height: 4rem;
  cursor: pointer;
  padding-left: 5px;
}

.method-title:hover {
  color: var(--light-blue);
}

.method > h3 {
  font-size: 1.5rem;
  text-transform: uppercase;
  margin-top: 1.3rem;
  text-align: center;
}

.service-list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: auto;
  width: fit-content;
}

.service-list > .card:hover .MuiTypography-body1 {
  color: var(--light-blue);
}

.MuiCardContent-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1000px) {
  .services .headline {
    font-size: 1.5rem;
  }

  .service-list {
    grid-template-columns: 1fr;
    width: 70%;
    margin: auto
  }

  .method-chart {
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .method-title {
    font-size: 1.2rem;
  }

  img.method-image {
    max-width: 200px;
  }

  .title-list {
    display: flex;
    font-size: 1rem;
    gap: 10px
  }
}

@media screen and (max-width: 480px) {
  .services .headline {
    font-size: 1.2rem;
  }

  .method-chart {
    display: flex;
    flex-direction: column;
  }

  .method-title {
    font-size: 1rem;
  }

  img.method-image {
    max-width: 150px;
  }
}