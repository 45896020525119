:root {
  --dark-gray: rgb(4, 5, 5);
  --light-blue: #11b1da;
}

* {
  font-family: 'Geologica', sans-serif;

}

html {
  background: url("./assets/bg.jpg");
  background-size: cover;
  color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  min-height: 100%;

}

a {
  color: white
}

body {
  width: 100vw;
  height: 100%;
  overflow-y: scroll;
  position: relative;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.788);


}

.App {
  padding: 0 4rem;
  margin: auto;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: left;


}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  height: 100px;

}

.center-links {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-transform: uppercase;
  padding: 0;
  font-size: 1rem;
  height: 100%;
}

.header-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.header-right p {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 5px
}

.header-right p a {
  text-decoration: none;

}


.btn {
  display: inline-block;
  border-radius: 5rem;
  color: #fff;
  text-transform: uppercase;
  background-color: rgba(4, 5, 5);
  letter-spacing: 0.1rem;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin: 0;
  padding: 0.5rem;
  box-shadow: rgb(28, 28, 29) 3px 3px 6px 0px;
}

.btn::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5rem;
  z-index: -2;
  margin: 0;
  padding: 0.5rem;
}

.btn:before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0%;
  background-color: #008fb3;
  transition: all 0.3s;
  border-radius: 5rem;
  z-index: -1;
}

.btn:hover {
  color: #fff;
}

.btn:hover:before {
  width: 100%;
  height: 100%;
}

.home {
  height: fit-content;
  font-family: 'Manrope', sans-serif;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.headline {

  display: inline-block;
  justify-self: start;
  align-self: flex-start;
  font-size: 3rem;
  margin-top: 10%;
  font-family: 'Raleway', sans-serif;
}

.featured {

  padding: 3rem;
  text-align: center;
  margin: auto;
  height: 100vh;
  padding: 10px;
}

.featured > ul > li {
  line-height: 3.5rem;
  font-size: 1.3em;
  font-family: 'Open Sans', sans-serif;
  list-style: none;
  text-align: center;
}

.featured > ul > li > svg {
  transform: scale(1.6);
  vertical-align: middle;
  margin-right: 10px;
}

.action {
  font-weight: bold;
  font-family: 'Manrope', sans-serif;
  overflow: hidden;
  border-right: .1em solid white;
  white-space: nowrap;
  animation: blink .8s infinite, type 1s steps(30, end) forwards;
  max-width: fit-content;
  color: var(--light-blue);
}

@keyframes type {
  from {
    width: 0
  }

  to {
    width: 100%;
  }
}

@keyframes blink {
  from {
    border-color: transparent
  }

  to {
    border-color: white;
  }
}

.intake-form {
  /* background-color: rgba(255, 255, 255, 0.87); */
  background-color: white;
  width: fit-content;
  height: fit-content;
  justify-self: center;
  margin: 20% auto 0% auto;
  padding: 15px;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.intake-form > button {
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.intake-form p {
  color: #11b1da;
  display: flex;
  align-items: center;
  margin: 0;
}

h5.MuiTypography-h5 {
  color: black;
  text-align: center;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-family: Geologica, sans-serif;
}

.scroll-up {
  position: fixed;
  bottom: 50px;
  right: 50px;
  transform: scale(2);
  border-radius: 50%;
  cursor: pointer;
  z-index: 500;
}

.header {
  position: relative;
  margin: 0 1rem;
}

.mobile-nav,
.hamburger {
  display: none;
}


#logo {
  width: 200px;
}

@media screen and (max-width: 1000px) {
  /* html {
    max-width: 100vw;
    margin: auto;
    overflow: hidden;
  } */

  .center-links {
    display: none;
  }

  .App {
    padding: 10px;
  }

  .home {
    grid-template-columns: 1fr;
  }

  .featured > ul > li {
    line-height: 2.5rem;
    font-size: 1.5rem;
  }

  .headline {
    font-size: 2rem;
  }

  .featured {
    height: 100%;
    padding: 5px;
  }

  .hamburger {
    display: inline-block;
    cursor: pointer;
  }

  .mobile-nav {
    display: block;
    position: relative;
  }

  .mobile-nav-links {
    position: absolute;
    top: 0;
    right: 0;
    min-width: max-content;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.836);
    padding: 1rem;
    font-size: 1.5rem;
    line-height: 2rem;
    border-radius: 1rem;
  }

  .mobile-nav-links a {
    cursor: pointer;
  }

  .mobile-nav-links a:hover {
    color: var(--light-blue)
  }


  #logo {
    width: 150px;
  }

  .header-right p {
    display: none;
  }
}

@media screen and (max-width: 480px) {


  .featured > ul > li {
    line-height: 1.5rem;
    font-size: 1rem;
  }


  .featured > ul > li > svg {
    transform: scale(1);
  }

  .headline {
    font-size: 1.5rem;
  }

  .featured {
    padding: 5px;
  }

  .intake-form {
    padding: 5px;
    width: 90%;
  }

  .scroll-up {
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  #logo {
    width: 110px;
  }
}

.center-links a:last-child span {
  color: black;
  background-color: white;
}

/* 

input:invalid {
  border-bottom: 2px dashed red;
}

input:focus:invalid {
  border-bottom: none;
} */

.error {
  color: red;
  font-size: 0.9rem;
  margin-top: 0;
  padding-top: 0;
}

.footer {
  font-size: 1.1rem;
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 500px;

  margin: 20 auto;
  text-align: center;
}

.footer ul {
  margin: 0;
  padding: 0;
  display: flex;
  gap: 15px;
  width: 500px;
  margin: auto;


  list-style-type: none;
  justify-content: center;
}


.footer ul li:hover {
  cursor: pointer;

}

.footer p {
  text-align: center;
}

.footer hr {
  color: white;
  width: 500px;
}