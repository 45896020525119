:root {
  --dark-gray: rgba(0, 0, 0, 0.788);
  --light-blue: #11b1da;
}

.about {
  height: 100%;
  min-height: 100vh;
}

.about h3 {
  font-size: 1.5rem;
  font-family: "Raleway", sans-serif;
}

.about-chart {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.scene {
  width: 90%;
  min-height: 220px;
  margin-top: 20px;
  perspective: 500px;
}

.card {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 1s;
  transform-style: preserve-3d;
}

.face {
  position: absolute;
  backface-visibility: hidden;
  border-radius: 1rem;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  ;
}

.face-front {
  background: white;
  font-size: 2rem;
  color: var(--dark-gray);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}

.face-back {
  background: var(--light-blue);
  transform: rotateY(180deg);
  font-size: 1.5rem;
  height: 100%;
  width: 100%;
  margin: auto;
  font-family: "Raleway", sans-serif;
  min-height: fit-content;

}

.card.is-flipped {
  transform: rotateY(180deg) translateX(-18px);
}

.face-front::after {
  content: " ";
  background: var(--light-blue);
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgb(5, 102, 109);
  height: 20px;
  width: 20px;
  transform: scale(1);
  animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(13, 71, 71, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@media screen and (max-width: 1000px) {
  .about .headline {
    font-size: 1.5rem;
  }

  .about h3 {
    font-size: 1rem;
  }

  .face-front {
    font-size: 1.7rem;
    padding: 5px;
    min-height: 180px;

  }

  .face-back {
    font-size: 1.3rem;
    padding: 5px;
    min-height: 180px;
  }

  .about-chart {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

@media screen and (max-width: 480px) {
  .about .headline {
    font-size: 1.2rem;
  }

  .about h3 {
    font-size: 0.8rem;
  }

  .about-chart {
    grid-template-columns: 1fr;
  }

  .face {
    height: auto;
  }

  .scene {
    height: 50px;
  }
}