:root {
  --dark-gray: rgb(4, 5, 5);
  --light-blue: #11b1da;
}

.pricing {
  height: 100%;
  min-height: 100vh;
}

.pricing > h3 {
  text-transform: uppercase;
  font-size: 1.1rem;
  text-align: center;
}

.pricing-chart {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  justify-content: center;
  justify-items: center;
}

.pricing > h3 {
  margin-top: 2rem;
}

.btn {
  cursor: pointer;
}

.pricing-chart ul > li {
  list-style-type: none;
  list-style-image: url(../assets/check.png);
  list-style-position: inside;
  text-align: left;
}

.pricing-chart .MuiTypography-h3 {
  font-weight: bold;
  font-size: 1.7em;
  color: rgb(49, 132, 170);
}

.pricing-chart span {
  margin: auto;
}

.pricing-chart .MuiPaper-root:hover {
  background-color: #edf3f5;
}

.MuiPaper-root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MuiCardActions-root {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.MuiCardContent-root p:nth-child(1) {
  text-transform: uppercase;
}

.MuiCardContent-root div {
  font-weight: bold;
}

@media screen and (max-width: 1000px) {
  .pricing .headline {
    font-size: 1.5rem;
  }

  .pricing > h3 {
    font-size: 1.2rem;
  }

  .pricing-chart {
    grid-template-columns: 1fr;
  }

  .pricing-chart > div {
    margin: auto;
  }
}

@media screen and (max-width: 480px) {
  .pricing .headline {
    font-size: 1.2rem;
  }
}